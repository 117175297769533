import { Entry, LayoutModel } from "@noble-bits/cms/lib/types/content-models";
import renderEntry from "./entry";

const renderLayout = (entry: Entry<LayoutModel>) => {

    let classNames = 'nb-layout';
    if(entry.fields.horizontalMode) {
        classNames += ' nb-layout--horizontal';
    }

    return (
        <div className={ classNames }>
            {entry.fields.content?.map(entry => {
                <div className="nb-layout__item">{renderEntry(entry)}</div>
            })}
        </div>
    );
}

export default renderLayout;