import { Entry, CardModel } from "@noble-bits/cms/lib/types/content-models";
import renderAsset from "./asset";

const renderCard = (entry: Entry<CardModel>) => {
    const {title, image, text} = entry.fields;

    let img = <></>;
    if(image) {
        img = <div className="nb-card__image" >{renderAsset(image)}</div>;
    }

    return (
        <div className="nb-card">
            {img}
            <span className="nb-card__title">{title}</span>
            <p className="nb-card__text">{text}</p>
        </div>
    );
}

export default renderCard;