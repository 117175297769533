import './footer.scss';

const Footer = () => {
    return (
        <footer className="nb-footer">
            <div className="nb-footer_container">
                <div className="nb-footer__item">
                    <p className="nb-text--bold">🚀 Noble Bits AB © {new Date().getFullYear()}</p>
                </div>
                <div className="nb-footer__item">
                    <p>info@noblebits.se</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;