import { getAppContext } from './../../lib/context/app-context';
import renderEntry from '../content/entry';
import './page.scss';

const Page = () => {
    const [state, { }] = getAppContext();
    return (
        <section className="ng-page nb-container">
            {state.page?.fields.content?.map(renderEntry)}
        </section>
    );
}

export default Page;