import { Asset } from "@noble-bits/cms/lib/types/content-models";

const renderAsset = (asset: Asset) => {
    const { file, description } = asset.fields;
    const { contentType, url } = file;

    if (contentType.indexOf('image/') !== -1) {
        return (
            <img
                src={url}
                alt={description}>
            </img>
        );
    }
    else {
        return <div>Not supporting content type: {contentType}</div>
    }

}

export default renderAsset;