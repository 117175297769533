import {
    HtmlModel,
    NodeType,
    Entry,
    RichTextContent,
    Asset
} from "@noble-bits/cms/lib/types/content-models";
import renderAsset from "./asset";

const resolveClasses = (marks: any[]) => {
    return marks?.map(mark => `nb-text--${mark.type}`).join(' ') ?? '';
};

const renderTextNode = (node: RichTextContent, marks: any[], markClass: string) => {
    if (marks.some((mark) => mark.type === 'code')) {
        return <code>{node.value}</code>
    } else if (markClass) {
        return <span className={markClass}>{node.value}</span>
    } else {
        return <>{node.value}</>
    }
}

const renderRichTextNodeRecursive = (node: RichTextContent) => {
    const { content, nodeType, marks } = node;
    const markClass = resolveClasses(marks);
    const childNodeContent = content?.map(renderRichTextNodeRecursive);

    switch ((nodeType) as any) {
        case NodeType.Document:
            return <div>{childNodeContent}</div>
        case NodeType.Text:
            return renderTextNode(node, marks, markClass);
        case NodeType.Paragraph:
            return <p className={markClass}>{childNodeContent}</p>;
        case NodeType.Heading1:
            return <h1 className={markClass}>{childNodeContent}</h1>
        case NodeType.Heading2:
            return <h2 className={markClass}>{childNodeContent}</h2>
        case NodeType.Heading3:
            return <h3 className={markClass}>{childNodeContent}</h3>
        case NodeType.Heading4:
            return <h4 className={markClass}>{childNodeContent}</h4>
        case NodeType.Heading5:
            return <h5 className={markClass}>{childNodeContent}</h5>
        case NodeType.Heading6:
            return <h6 className={markClass}>{childNodeContent}</h6>
        case NodeType.Hyperlink:
        case NodeType.EntryHyperlink:
            return <a href={node.data.uri}>{childNodeContent}</a>
        case NodeType.UnorderedList:
            return <ul>{childNodeContent}</ul>;
        case NodeType.OrderedList:
            return <ol>{childNodeContent}</ol>;
        case NodeType.ListItem:
            return <li>{childNodeContent}</li>;
        case NodeType.EmbeddedAssetBlock:
            const { target } = node.data as any;
            return renderAsset(target as Asset);
        case NodeType.Hr:
            return <hr></hr>
        case NodeType.Blockquote:
            return <blockquote>{childNodeContent}</blockquote>
        default:
            console.log('Missing', node);
            return <p>Missing node type {nodeType}</p>
    }
}

const renderRichText = (entry: Entry<HtmlModel>) => {
    return renderRichTextNodeRecursive(entry.fields.value as any);
};

export default renderRichText;