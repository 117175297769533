import 'firacode/distr/fira_code.css';
import 'animate.css/animate.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { render } from 'solid-js/dom';
import { App } from './components';
import { AppContextProvider } from "./lib/context/app-context";

const Index = () => {
  return (
    <AppContextProvider>
      <App></App>
    </AppContextProvider>
  );
}

render(Index, document.getElementById("Page") as Element);
