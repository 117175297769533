import { Entry } from "@noble-bits/cms/lib/types/content-models";
import { ContentType } from "@noble-bits/cms/lib/types/enums/content-type";
import renderText from "./text";
import renderLayout from "./layout";
import renderRichText from "./rich-text";
import renderBlogPost from "./blog-post";
import renderHeader from "./header";
import renderCard from "./card";
import renderSection from "./section";

const renderEntry = (entry: Entry<any>): JSX.Element => {
    const { sys } = entry;
    
    switch (sys.type) {
        case 'Entry':
            const { id } = sys.contentType.sys;
            switch (id) {
                case ContentType.Text:
                    return renderText(entry);
                case ContentType.BlogPost:
                    return renderBlogPost(entry);
                case ContentType.Html:
                    return renderRichText(entry);
                case ContentType.Layout:
                    return renderLayout(entry);
                case ContentType.Header:
                    return renderHeader(entry);
                case ContentType.Card:
                    return renderCard(entry);
                case ContentType.Section:
                    return renderSection(entry);
                default:
                    return <div>Missing field type {id}</div>;
            }
        case 'Link':
            return <div>!!!This link needs to be resolved!!!</div>
    }

    return <></>
}

export default renderEntry;