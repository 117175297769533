import { Entry, HeaderModel } from "@noble-bits/cms/lib/types/content-models";

const renderHeader = (entry: Entry<HeaderModel>): JSX.Element => {
    const {value, size, center} = entry.fields;

    let classNames = '';
    if(center) {
        classNames += 'nb-text--center';
    }

    switch(size) {
        case 1:
            return <h1 className={classNames}>{value}</h1>;
        case 2:
            return <h2 className={classNames}>{value}</h2>;
        case 3:
            return <h3 className={classNames}>{value}</h3>;
        case 4:
            return <h4 className={classNames}>{value}</h4>;
        case 5:
            return <h5 className={classNames}>{value}</h5>;
        case 6:
            return <h6 className={classNames}>{value}</h6>;
        default:
            return <h1 className={classNames}>{value}</h1>
    }
}

export default renderHeader;