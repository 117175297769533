import { createContext, createState, useContext } from "solid-js";
import { Wrapped } from "solid-js/types/state";
import AppState from "../types/app-state";

import ContentService from '@noble-bits/cms/lib/services/content-service';
import { PageModel, Entry } from "@noble-bits/cms/lib/types/content-models";

export type AppContext = [
  Wrapped<AppState>,
  {
    init: () => void;
    loadPage: (pageEntry: Entry<PageModel>) => void;
  }
];

const getInitialAppState = (): AppState => {
  let state: AppState | null = null;
  return state || new AppState();
};

const contentService = new ContentService();

const Context = createContext<AppContext>();
export function AppContextProvider(props: any) {
  const [state, setState] = createState<AppState>(getInitialAppState());

  window.addEventListener('popstate', (event) => {
    setState({
      'page': undefined,
      'loadingPage': true
    });
    setState({
        'page': event.state,
        'loadingPage': false
      });
  });
  
  const store: AppContext = [
    state,
    {
      init: async () => {
        setState('root', await contentService.getRoot());
        const page = await contentService.getPageByUrl(window.location.pathname);
        history.replaceState(page, page?.fields.title, window.location.pathname);
        setState({
          'page': page,
          'loadingPage': false
        });
      },
      loadPage: async (pageEntry: Entry<PageModel>) => {
        if(pageEntry.sys.id === state.page?.sys.id) {
          return;
        }

        setState({
          'page': undefined,
          'loadingPage': true
        });

        const includeDepth = pageEntry.fields.depth ?? 3;
        const page = await contentService.getEntry<PageModel>(pageEntry.sys.id, pageEntry.fields.depth ?? 3);
        history.pushState(page, pageEntry.fields.title, pageEntry.fields.url);
        setState({
          'page': page,
          'loadingPage': false
        });
      }
    }
  ];

  return (<Context.Provider value={store}>{props.children}</Context.Provider>);
}

export const getAppContext = (): AppContext => useContext<AppContext>(Context);