import {
  Entry,
  RootModel,
  PageModel
} from '@noble-bits/cms/lib/types/content-models';

export default class AppState {
  root?: Entry<RootModel>;
  loadingPage: boolean;
  page?: Entry<PageModel>;

  constructor() {
    this.loadingPage = true;
  }
}