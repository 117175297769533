import './header.scss';
import { getAppContext } from '../../lib/context/app-context';

const Header = () => {
    const [state, { loadPage }] = getAppContext();

    return (
        <header className="nb-header animated fadeInDown faster">
            <h1 className="nb-header__title">Noble Bits</h1>
            <nav>
                <div className="nb-header__item-group">
                    <ul className="nb-header__menu">
                        {state.root?.fields.links.map((link) => {
                            let menuItemClassName = 'nb-header__menu__item';
                            if (link.sys.id === state.page?.sys.id) {
                                menuItemClassName += ' nb-header__menu__item--active';
                            }

                            let icon = <></>
                            if(link.fields.icon) {
                                let iconIcon = `fas fa-${link.fields.icon}`;
                                icon = <i className={iconIcon}></i>
                            }

                            <li className={menuItemClassName}>
                                <a
                                    href={link.fields.url}
                                    onClick={() => {
                                        event?.preventDefault();
                                        loadPage(link);
                                    }}
                                >
                                    <span className="nb-header__menu__item__link_text">{link.fields.title}</span>
                                    {icon}
                                </a>
                            </li>
                        })}
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;