import { getAppContext } from "../../lib/context/app-context";
import { Header, Footer, Page } from "..";

const App = () => {
    const [state, { init }] = getAppContext();
    init();
    return (
        <>
            <Header></Header>
            <Page></Page>
            <Footer></Footer>
        </>
    );
}

export default App;