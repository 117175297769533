import { Entry, BlogPostModel } from '@noble-bits/cms/lib/types/content-models';
import renderAsset from './asset';

const renderBlogPost = (entry: Entry<BlogPostModel>) => {
    const { title, image } = entry.fields;
    return (
        <div>
            <h2>{title}</h2>
            {renderAsset(image)}
        </div>
    )
}

export default renderBlogPost;