import { Entry, SectionModel } from "@noble-bits/cms/lib/types/content-models";
import renderEntry from "./entry";

const renderSection = (entry: Entry<SectionModel>) => {

    const { title, description, content, highlight } = entry.fields;

    let sectionTitle = <></>;
    if (title) {
        sectionTitle = <h2 className="nb-text--center">{title}</h2>
    }

    let sectionDescription = <></>;
    if (description) {
        sectionDescription = <p className="nb-text--center">{description}</p>;
    }

    let sectionClassNames = 'nb-section animated fadeInUp faster';
    if(highlight) {
        sectionClassNames += ` nb-section--highlight-${highlight}`;
    }

    let sectionChild = <></>
    if(content) {
        sectionChild = renderEntry(content);
    }

    return (
        <div className={sectionClassNames}>
            {sectionTitle}
            {sectionDescription}
            {sectionChild}
        </div>
    );
}

export default renderSection;